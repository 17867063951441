const {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} = window["Recharts"];

function fillMissingDates(data, startDate, endDate) {
  const newData = [];

  while (startDate <= endDate) {
    const dateString = startDate.toISOString().slice(0, 10);
    const existingData = data.find((item) => {
      const originalDate = new Date(item.day);
      originalDate.setDate(originalDate.getDate());
      const modifiedDate = originalDate.toISOString().slice(0, 10);
      return modifiedDate === dateString;
    });

    if (existingData) {
      newData.push({
        day: existingData.day,
        returnCount: existingData.returnCount,
        createCount: existingData.createCount,
      });
    } else {
      newData.push({ day: dateString, returnCount: 0, createCount: 0 });
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  return newData;
}

function ReportChart(props) {
  let { dateTime, users, lang, formatDateCustom } = props;
  const startDate = new Date(dateTime.start_date);
  const endDate = new Date(dateTime.end_date);

  const user = _.cloneDeep(users) || [];

  const filledData = fillMissingDates(user, startDate, endDate);

  let data = filledData.map((val, index) => {
    return {
      name: formatDateCustom(val.day, "DD MMM"),
      [lang.lang.__new_user]: val.createCount || 0,
      [lang.lang.__return_user]: val.returnCount || 0,
    };
  });

  return (
    <div style={{ paddingRight: "8%", marginTop: 30 }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis fontSize={14} dataKey="name" type="category" />
          <YAxis fontSize={14} />
          <Tooltip cursor={{ fill: "#f2f8ff99" }} />
          <Legend />
          <Bar stackId="a" dataKey={lang.lang.__return_user} fill="#65A6FF" />
          <Bar stackId="a" dataKey={lang.lang.__new_user} fill="#27DB7A" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
